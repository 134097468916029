import style from '../pages/style/company.module.scss'

import blue01 from '../images/company/plants/blue-01.svg'
import blue02 from '../images/company/plants/blue-02.svg'
import blue03 from '../images/company/plants/blue-03.svg'
import blue04 from '../images/company/plants/blue-04.svg'
import green01 from '../images/company/plants/green-01.svg'
import green02 from '../images/company/plants/green-02.svg'
import yellow01 from '../images/company/plants/yellow-01.svg'
import yellow02 from '../images/company/plants/yellow-02.svg'
import yellow03 from '../images/company/plants/yellow-03.svg'

// leaf

export const paralaxPlants = [{
	id: style.green02,
	src: green02,
	alt: 'green algae',
	data: {
		'data-start': 'transform: rotate(15deg)',
		'data-end': 'transform: rotate(-25deg)',
	},
},{
	id: style.yellow03,
	src: yellow03,
	alt: 'yellow algae',
	data: {
		'data-start': 'transform: rotate(15deg)',
		'data-end': 'transform: rotate(-30deg)',
	},
},{
	id: style.blue03,
	src: blue03,
	alt: 'blue algae',
	data: {
		'data-start': 'transform: rotate(-5deg)',
		'data-end': 'transform: rotate(25deg)',
	},
},{
	id: style.blue01,
	src: blue01,
	alt: 'blue algae',
	data: {
		'data-start': 'transform: rotate(-15deg)',
		'data-end': 'transform: rotate(25deg)',
	},
},];

export const paralaxPlants02 = [{
	id: style.blue04,
	src: blue04,
	alt: 'blue algae',
	data: {
		'data-start': 'transform: rotate(-25deg)',
		'data-end': 'transform: rotate(5deg)',
	},
},{
	id: style.green01,
	src: green01,
	alt: 'green algae',
	data: {
		'data-start': 'transform: rotate(25deg)',
		'data-end': 'transform: rotate(-5deg)',
	},
},{
	id: style.yellow01,
	src: yellow01,
	alt: 'yellow algae',
	data: {
		'data-start': 'transform: rotate(-25deg)',
		'data-end': 'transform: rotate(5deg)',
	},
},{
	id: style.yellow02,
	src: yellow02,
	alt: 'yellow algae',
	data: {
		'data-start': 'transform: rotate(25deg)',
		'data-end': 'transform: rotate(-5deg)',
	},
},];

export const fixedPlant = [{
	id: style.blue02,
	src: blue02,
	alt: 'blue algae',
}]