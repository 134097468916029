import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slick from 'react-slick'
import Img from "gatsby-image"

// import Loadable from "react-loadable"
import loadable from '@loadable/component'


import Layout from "../components/layout"
import SEO from "../components/seo"

import GridLayout, { GridItem } from '../components/utils/GridLayout'

import style from './style/company.module.scss'
import headerStyle from '../components/style/header.module.scss'

import hands from '../images/company/hands.svg'
import OurStory from '../components/images/OurStory'
import FoodLeader from '../components/images/FoodLeader'


import { 
	paralaxPlants,
	paralaxPlants02,
	fixedPlant
} from '../plants/company-plants'

/**
 * Lazy loader
 */
// const loader=()=>(<div>Loading...</div>)

// import ParalaxPlant from "../components/utils/ParalaxPlant"
// import ParallaxProvider from "../components/utils/Parallax"

const ParalaxPlant = loadable(() => import("../components/utils/ParalaxPlant"));
const ParallaxProvider = loadable(() => import("../components/utils/Parallax"));

const slickBanner = {
  arrows: false,
  dots: false,
  infinite: true,
  draggable: false,
  variableWidth: true,
  autoplay: true,
  speed: 3200,
  autoplaySpeed: 1,
  cssEase: "linear"
}


const CompanyPage = () => {
	const { 
		hiringYaml, 
		companyYaml, 
		allPartnersYaml 
	} = useStaticQuery(graphql`
		{
			hiringYaml {
		    button {
		      link
		      text
		    }
		    title
		  }
			allPartnersYaml {
			  nodes {
			    name
			    mobileImg: img {
			      childImageSharp {
			        fluid(maxWidth: 60, quality: 100) {
			          ...GatsbyImageSharpFluid
			        }
			      }
			    }
			    desktopImg: img {
			      childImageSharp {
			        fluid(maxWidth: 120, quality: 100) {
			          ...GatsbyImageSharpFluid
			        }
			      }
			    }
			  }
			}
	  	companyYaml {
  	    id
  	    title
  	    mission_statement
  	    partners
  	    title_h2
  	    food {
  	      content
  	      title
  	    }
  	    story {
  	      content
  	      title
  	    }
  	    team {
  	    	title
  	    	content
  	    }
  	  }
  	}
 	`)

 	const partners = allPartnersYaml.nodes;
 	const partnersMobile = [
 	  partners.slice(0, parseInt(partners.length / 2)),
 	  partners.slice(parseInt(partners.length / 2)),
 	];

	return (
	  <Layout pageName = { headerStyle.CompanyPage + ' companyPage' } >
	  	<div id = { style.Company } >
				<SEO 
					title="Company" 
					description = "Algama’s goal is to feed 10 billion people by 2050, while preserving the planet and offering consumers additional and better choices in foods." />

				{/* Header */}
				<div id = { style.CompanyHeader }>
					<GridLayout>
						<GridItem grid = {{
							col: { md: 10, lg: 8 },
							push: { md: 1, lg: 2 },
						}}>
							<h1 className = 'title title--h3'>
								{ companyYaml.title }
							</h1>
							<h2 className = 'title title--pages'>
								{ companyYaml.title_h2 }
							</h2>
						</GridItem>
					</GridLayout>
				</div>

				{/* Mission statement */}
				<ParallaxProvider>
					<div id = { style.CompanyMission }>

						{ 
							paralaxPlants.map((plant, key) => (
							  <ParalaxPlant
							    key = { key }
							    className = { style.MovingPlant } 
							    { ...plant } />
							)) 
						}

						<GridLayout>
							<GridItem grid = {{
								col: { md: 10, lg: 8, sm: 10 },
								push: { md: 1, lg: 2, sm: 1 },
							}}>
								<p className = 'crps crps--heavy'>
									{ companyYaml.mission_statement }
								</p>
							</GridItem>
						</GridLayout>
					</div>
				</ParallaxProvider>

				<div id = { style.Sections }>

					{ 
						fixedPlant.map((plant, key) => (
						  <ParalaxPlant
						    key = { key }
						    className = { style.MovingPlant } 
						    { ...plant } />
						)) 
					}

					{/* Story */}
					<div id = { style.Story }>
						
						<GridLayout 
							className = 'grid-flex--center'>
							<GridItem 
								grid = {{
									col: { lg: 6, md: 6 },
								}}
								className = 'grid-flex__item'>
								<OurStory
									className = { style.StoryImg } 
									alt = { companyYaml.story.title } />
							</GridItem>
							<GridItem 
								grid = {{
									push: { lg: 1, md: 1 },
									col: { lg: 5, md: 5 },
								}}
								className = 'grid-flex__item'>
								<h2 className = 'title title--h4'>
									{ companyYaml.story.title }
								</h2>
								<p
									dangerouslySetInnerHTML = {{ __html: companyYaml.story.content }} 
									className = 'crps'/>
							</GridItem>
						</GridLayout>
					
					</div>

					{/* Leader */}
					<div id = { style.Leader }>
						
						<GridLayout 
							className = 'grid-flex--center grid-flex--reverse-md'>
							<GridItem 
								grid = {{
									col: { lg: 6, md: 6 },
								}}
								className = 'grid-flex__item'>
								<FoodLeader 
									className = { style.FoodImg }
									alt = { companyYaml.food.title } />
							</GridItem>
							<GridItem 
								grid = {{
									pull: { lg: 1, md: 1 },
									col: { lg: 5, md: 5 },
								}}
								className = 'grid-flex__item'>
								<h2 className = 'title title--h4'>
									{ companyYaml.food.title }
								</h2>
								<p
									dangerouslySetInnerHTML = {{ __html: companyYaml.food.content }}  
									className = 'crps'/>
							</GridItem>
						</GridLayout>
					
					</div>
				</div>

				{/* Partners */}
				<div id = { style.Partners }>
				  <div className = 'wrapper'>
				    <h2 className = 'title title--h3'>
				      Our partners
				    </h2>
				  </div>
				  <div className = 'mobile-only'>
				    <div className = { style.PartnersSlider}>
				      <Slick { ...slickBanner }>
				        { partnersMobile[0].map(({mobileImg, desktopImg, name}, i) => (
				          <div key = { i } >
				            <div 
				              style = {{
				                width: mobileImg.childImageSharp.fluid.aspectRatio * 88
				              }}
				              className = { style.partner }>
				              <Img fluid = { mobileImg.childImageSharp.fluid } />
				            </div>
				          </div>
				        ))}
				      </Slick>
				    </div>
				    <Slick { ...slickBanner }>
				      { partnersMobile[1].map(({mobileImg, name}, i) => (
				        <div key = { i } >
				          <div 
				            style = {{
				              width: mobileImg.childImageSharp.fluid.aspectRatio * 88
				            }}
				            className = { style.partner }>
				            <Img fluid = { mobileImg.childImageSharp.fluid } />
				          </div>
				        </div>
				       ))}
				    </Slick>
				  </div>
				  <div className = 'desktop-only'>
				    <GridLayout
				      className = 'grid-flex--center'>
				      { partners.map(({mobileImg, desktopImg, name}, i) => (
				        <GridItem 
				          className = 'grid-flex__item'
				          grid = {{
				            col: { md: 'fifth', lg: 'fifth' }
				          }}
				          key = { i }>
				          <div className = { style.partner }>
				            <Img fluid = { desktopImg.childImageSharp.fluid } />
				          </div>
				        </GridItem>
				      ))}
				    </GridLayout>
				  </div>
				</div>

				{/* Cover */}
				<div id = { style.Cover } />

				{/* Team */}
				<div id = { style.Team }>
					<GridLayout
						className = 'grid-flex--center grid-flex--reverse-md'>
						<GridItem 
							grid = {{
								col: { lg: 5, md: 5 },
							}}
							className = 'grid-flex__item'>
							<div className = { style.hands }>
								<img 
									src = { hands } 
									alt = { companyYaml.team.title } />
							</div>
						</GridItem>
						<GridItem 
							grid = {{
								col: { lg: 5, md: 5 },
								pull: { lg: 2, md: 2 },
							}}
							className = 'grid-flex__item'>
							<h2 className = "title title--h4">
								{ companyYaml.team.title }
							</h2>
							<p
								dangerouslySetInnerHTML = {{ __html: companyYaml.team.content }}   
								className = 'crps'/>
						</GridItem>
					</GridLayout>
				</div>

				{/* Hiring */}
				<ParallaxProvider>
					<div id = { style.Hiring }>

						{ 
							paralaxPlants02.map((plant, key) => (
							  <ParalaxPlant
							    key = { key }
							    className = { style.MovingPlant } 
							    { ...plant } />
							)) 
						}

						<div className = 'wrapper'>
							<h2 className = 'title title--h4'>
								{ hiringYaml.title }
							</h2>
							<a 
								href = { hiringYaml.button.link } 
								target = '_blank'
								rel="noopener noreferrer"
								className = 'button button--clear'>
								{ hiringYaml.button.text }
							</a>
						</div>
					</div>
				</ParallaxProvider>
	  	</div>
	  </Layout>
	)
}

export default CompanyPage
